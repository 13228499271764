import { useList } from '@zupr/hooks/request-redux'
import { informationRequest as informationRequestQuery } from '@zupr/queries/information-request'
import {
    Location,
    Product,
    ProductLocation,
    ShoppingList,
    ShoppingListItem,
} from '@zupr/types/fo'
import { Query, QueryInformationRequestArgs } from '@zupr/types/graphql'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { getStorageJson, setStorageJson } from '../utils/storage'

interface ProductLocations {
    results: ProductLocation[]
}
interface Products {
    results: Product[]
}

interface ShoppingListContextProvider {
    numberOfProducts: number
    productLocations: ProductLocations
    products: Products
    lists: ShoppingList[]
    showSidebar: boolean
    updateList: (list: ShoppingList) => void
    removeList: (locationId: Location['id']) => void
    createList: (locationId: Location['id']) => ShoppingList
    onOpenSidebar: () => void
    onCloseSidebar: () => void
    onToggleSidebar: () => void
}

export const ShoppingListContext =
    React.createContext<ShoppingListContextProvider>(
        {} as ShoppingListContextProvider
    )
export default ShoppingListContext

const isPayable = ({ productLocation }: ShoppingListItem) => {
    if (productLocation.currency !== 'EUR') return false
    if (productLocation.price < 1) return false
    return true
}

const isInStock = ({
    quantity,
    productLocation: { stock_prefs, stock_count },
    productInformationRequest,
}: ShoppingListItem) => {
    // latest available information from location
    if (productInformationRequest) {
        if (productInformationRequest.quantityInStock >= quantity) return true
    }
    // if product is always stock
    if (stock_prefs === 'in_stock') return true
    // there is more stock available than requested
    if (stock_prefs === 'exact_stock' && stock_count >= quantity) return true
    // if there is no extra information
    return false
}

const isListRequesting = ({ informationRequest, items }: ShoppingList) => {
    if (!informationRequest) return false

    // if answered the user is not requesting
    if (informationRequest?.answered) return false

    // are some of the requested product still in the list
    return informationRequest.productInformationRequests.some(({ productId }) =>
        items.find((item) => item.productId === productId)
    )
}

const InformationRequestSubscription = ({ list, updateList }) => {
    const [result, reexecute] = useQuery<Query, QueryInformationRequestArgs>({
        query: informationRequestQuery,
        variables: { id: list.informationRequestId },
        pause: !list.informationRequestId,
    })

    const informationRequest = result?.data?.informationRequest
    const modified = informationRequest?.modified

    const onUpdate = useCallback(
        (informationRequest) => {
            updateList({
                ...list,
                informationRequest,
            })
        },
        [list, updateList]
    )

    useEffect(() => {
        if (!informationRequest) return
        onUpdate(informationRequest)
    }, [modified]) // eslint-disable-line react-hooks/exhaustive-deps

    // const [update] = useSubscription({
    //     query: onInformationRequestChanged,
    //     variables: { id: list.informationRequestId },
    //     pause: !list.informationRequestId,
    // })

    // const informationRequestUpdate =
    //     update?.data?.onInformationRequestChanged?.informationRequest

    // useEffect(() => {
    //     if (!informationRequestUpdate) return
    //     onUpdate(informationRequestUpdate)
    // }, [informationRequestUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    // long poll the information request
    useEffect(() => {
        if (!list.informationRequestId) return
        const interval = setInterval(() => {
            reexecute({
                requestPolicy: 'network-only',
            })
        }, 1000 * 10)
        return () => clearInterval(interval)
    }, [list.informationRequestId, reexecute])

    return null
}

interface ShoppingListProviderProps {
    children: React.ReactNode
}

export const ShoppingListProvider = ({
    children,
}: ShoppingListProviderProps) => {
    const [lists, setLists] = useState<ShoppingList[]>(
        getStorageJson('shopping-lists') || []
    )
    const [showSidebar, setShowSidebar] = useState(false)

    // get the plIds
    const plIds = useMemo(() => {
        const ids = []
        lists.forEach(({ items }) => {
            items.forEach(({ productLocationId }) => {
                ids.push(productLocationId)
            })
        })
        return ids
    }, [lists])

    // get the productIds
    const productIds = useMemo(() => {
        const ids = []
        lists.forEach(({ items }) => {
            items.forEach(({ productId }) => {
                ids.push(productId)
            })
        })
        return ids
    }, [lists])

    const locationIds = useMemo(() => {
        return lists.map(({ locationId }) => locationId)
    }, [lists])

    // fetch locations
    const [locations] = useList<Location>({
        url: 'fo/location',
        variables: {
            limit: locationIds.length,
            id__in: locationIds.join(','),
        },
        pause: !locationIds.length,
    })

    // fetch products
    const [products] = useList<Product>({
        url: 'fo/product',
        variables: {
            limit: productIds.length,
            id__in: productIds.join(','),
        },
        pause: !productIds.length,
    })

    // fetch pls?
    const [productLocations] = useList<ProductLocation>({
        url: 'fo/product-location',
        variables: {
            limit: plIds.length,
            id__in: plIds.join(','),
        },
        pause: !plIds.length,
    })

    // store changes and cleanup the object
    useEffect(() => {
        setStorageJson(
            'shopping-lists',
            lists.map(
                ({
                    locationId,
                    items,
                    informationRequestId,
                    isSeen,
                    orderId,
                    orderType,
                    remarks,
                    wrappingService,
                    isPickup,
                    pickupOrDeliveryConfirmed,
                    deliveryMethod,
                    deliveryMethodConfirmed,
                    isPayment,
                    paymentConfirmed,
                    isReservation,
                    reservationConfirmed,
                }) => ({
                    items: items.map(
                        ({
                            productId,
                            productLocationId,
                            quantity,
                            remarks,
                        }) => ({
                            productId,
                            productLocationId,
                            quantity,
                            remarks,
                        })
                    ),
                    locationId,
                    orderId,
                    informationRequestId,
                    isSeen,
                    orderType,
                    remarks,
                    wrappingService,
                    isPickup,
                    pickupOrDeliveryConfirmed,
                    deliveryMethod,
                    deliveryMethodConfirmed,
                    isPayment,
                    paymentConfirmed,
                    isReservation,
                    reservationConfirmed,
                })
            )
        )
    }, [lists])

    const updateList = useCallback((update: ShoppingList) => {
        setLists((lists) => {
            // new list
            if (!lists.find((list) => list.locationId === update.locationId)) {
                return [...lists, update]
            }

            // existing list
            return lists.map((list) => {
                if (list.locationId !== update.locationId) return list
                return { ...list, ...update }
            })
        })
    }, [])

    const removeList = useCallback((locationId) => {
        setLists((lists) =>
            lists.filter((list) => list.locationId !== locationId)
        )
    }, [])

    const updateListItem = useCallback((list, productLocationId, data) => {
        list.onUpdate({
            items: list.items.map((itemInList) => {
                if (itemInList.productLocationId !== productLocationId) {
                    return itemInList
                }
                return {
                    ...itemInList,
                    ...data,
                }
            }),
        })
    }, [])

    const attachCallbacks = useCallback(
        (list: Partial<ShoppingList>): void => {
            list.onUpdate = (data) => {
                updateList({
                    ...list,
                    ...data,
                } as ShoppingList)
            }
        },
        [updateList]
    )

    // creates empty shopping list
    const createList = useCallback(
        (locationId: Location['id']) => {
            const list = {
                locationId,
                items: [],
            }
            attachCallbacks(list)
            return list as ShoppingList
        },
        [attachCallbacks]
    )

    const onToggleSidebar = useCallback(() => {
        setShowSidebar((showSidebar) => !showSidebar)
    }, [])

    const onCloseSidebar = useCallback(() => {
        setShowSidebar(false)
    }, [])

    const onOpenSidebar = useCallback(() => {
        setShowSidebar(true)
    }, [])

    // add data to existing lists
    const extendedLists = useMemo(() => {
        // attach the list.onUpdate and list.createItem callback
        lists.forEach(attachCallbacks)

        // attach the list.item.onUpdate callback
        lists.forEach((list) => {
            list.items.forEach((item) => {
                item.onUpdate = (data) => {
                    updateListItem(list, item.productLocationId, data)
                }
            })
        })

        // attach the productInformationRequests
        lists.forEach((list) => {
            if (!list.informationRequest) return
            list.items.forEach((item) => {
                list.informationRequest.productInformationRequests.forEach(
                    (pir) => {
                        if (item.productId === pir.productId) {
                            item.productInformationRequest = pir
                        }
                    }
                )
            })
        })

        locations?.results.forEach((location) => {
            lists.forEach((list) => {
                if (list.locationId === location.id) {
                    list.location = location
                }
            })
        })

        products?.results.forEach((product) => {
            lists.forEach(({ items }) => {
                items.forEach((item) => {
                    if (item.productId === product.id) {
                        item.product = product
                    }
                })
            })
        })

        productLocations?.results.forEach((productLocation) => {
            lists.forEach(({ items }) => {
                items.forEach((item) => {
                    if (item.productLocationId === productLocation.id) {
                        item.productLocation = productLocation
                    }
                })
            })
        })

        // add product locations to the list
        productLocations?.results.forEach((pl) => {
            const list = lists.find(
                ({ locationId }) => locationId === pl.location.id
            )
            if (!list) return

            list.isRequesting =
                !!list.informationRequestId && isListRequesting(list)

            list.isAnswered =
                !!list.informationRequestId && list.informationRequest?.answered

            list.items.forEach((item) => {
                if (item.productLocationId === pl.id) {
                    item.isInStock = isInStock(item)
                    item.isPayable = isPayable(item)
                    item.location = pl.location
                }
            })
        })

        return lists
    }, [
        attachCallbacks,
        lists,
        locations?.results,
        productLocations?.results,
        products?.results,
        updateListItem,
    ])

    const value = useMemo(
        () => ({
            numberOfProducts: plIds.length,
            productLocations,
            products,
            lists: extendedLists,
            showSidebar,
            createList,
            updateList,
            removeList,
            onOpenSidebar,
            onCloseSidebar,
            onToggleSidebar,
        }),
        [
            createList,
            extendedLists,
            onCloseSidebar,
            onOpenSidebar,
            onToggleSidebar,
            plIds.length,
            productLocations,
            products,
            removeList,
            showSidebar,
            updateList,
        ]
    )

    return (
        <ShoppingListContext.Provider value={value}>
            {children}
            {lists.map((list) => (
                <InformationRequestSubscription
                    key={list.locationId}
                    list={list}
                    updateList={updateList}
                />
            ))}
        </ShoppingListContext.Provider>
    )
}
